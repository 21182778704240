$corPrim : #ab1711;
$corSec1 : #7E1418;
$corSec2 : #972C30;
$corSec3 : #A54145;
$corSec4 : #7e8a90;

$greenBtn : #BA8A49;
$greenDarkBtn : #B18141;
$cinzaBG : #F4F4F4;
$corCinza : #EAEAEA;
$corBranca : #FFFFFF;
$corB00 : #BB0000;
$corRedLight : #ff4131;
$corRedDark : #df4131;
$cor07 : #070707;
$cor7F : #7F7F7F;
$corBF : #BFBFBF;
$corE4 : #E4E4E4;
$corF2 : #F2F2F2;
$corF4 : #F4F4F4;
$preto25: rgba(0,0,0,0.25);
$preto13: rgba(0,0,0,0.13);
